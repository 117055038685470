"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMount = void 0;
const react_1 = require("react");
const useMount = () => {
    const [isMount, setMount] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        setMount(true);
        return () => setMount(false);
    }, []);
    return isMount;
};
exports.useMount = useMount;
