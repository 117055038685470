"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFeaturedFilters = exports.slugMatch = exports.getFiltersFromQuery = exports.generateQueryParams = exports.updateActiveFilters = exports.getFilters = exports.getFilteredProducts = exports.hasQueryParams = void 0;
const hasQueryParams = (asPath, param = null) => {
    return !!asPath.split(`?${param ? `${param}=` : ''}`)[1];
};
exports.hasQueryParams = hasQueryParams;
const sortProducts = (products, sortBy = null) => {
    const _products = Array.from(products);
    if (!sortBy || Object.keys(sortBy)[0] === 'recommended') {
        return _products;
    }
    const ascending = Object.keys(sortBy)[0] === 'low-price';
    _products.sort((a, b) => {
        const a_price_per_unit = a.variant.prices.price_ex_tax;
        const b_price_per_unit = b.variant.prices.price_ex_tax;
        return ascending ? a_price_per_unit - b_price_per_unit : b_price_per_unit - a_price_per_unit;
    });
    return _products;
};
const getFilteredProducts = (products = [], appliedFilters) => {
    var _a;
    if ((products === null || products === void 0 ? void 0 : products.length) < 1)
        return [];
    if (Object.keys(appliedFilters).length < 1)
        return sortProducts(products);
    // Parse appliedFilters into keyparams as array of true values
    // sample: { size: ['s', 'm'], color: ['white', 'blue', 'yellow']}
    const parsedFilters = {};
    for (const filterParam in appliedFilters) {
        if (typeof appliedFilters[filterParam] === 'boolean') {
            parsedFilters[filterParam] = true;
        }
        else {
            if (filterParam !== 'sort') {
                const values = appliedFilters[filterParam];
                if (typeof values === 'object' && Object.keys(values).length > 0)
                    parsedFilters[filterParam] = values;
            }
        }
    }
    if (Object.keys(parsedFilters).length < 1)
        return sortProducts(products, appliedFilters.sort);
    // If something to filter
    const filteredProducts = [];
    for (const product of products) {
        let matchAllFilters = false;
        // Product must match all filterParam
        for (const filterParam in parsedFilters) {
            if ((_a = product === null || product === void 0 ? void 0 : product.filters) === null || _a === void 0 ? void 0 : _a.some(filter => filter.param === filterParam)) {
                // product has this filter param, now lets check if match at least one param value
                for (const filter of product.filters) {
                    if (filter.param === filterParam) {
                        if (filter.type === 'simple') {
                            matchAllFilters = true;
                        }
                        else {
                            const values = filter.values.map((value) => value.value);
                            //@ts-ignore
                            const matchedValues = [...new Set(Object.keys(parsedFilters[filterParam]).filter(val => values.includes(val)))];
                            matchAllFilters = matchedValues.length > 0;
                        }
                        break;
                    }
                }
            }
            else {
                matchAllFilters = false;
                break;
            }
            if (!matchAllFilters)
                break;
        }
        if (matchAllFilters)
            filteredProducts.push(product);
    }
    return sortProducts(filteredProducts, appliedFilters.sort);
};
exports.getFilteredProducts = getFilteredProducts;
const TRANSLATIONS_SORT_BY = {
    'en-GB': {
        name: 'Sort',
        values: [
            { name: 'Recommended', value: 'recommended' },
            { name: 'Lowest price', value: 'low-price' },
            { name: 'Highest price', value: 'high-price' },
        ]
    },
    'en-EU': {
        name: 'Sort',
        values: [
            { name: 'Recommended', value: 'recommended' },
            { name: 'Lowest price', value: 'low-price' },
            { name: 'Highest price', value: 'high-price' },
        ]
    },
    'es-ES': {
        name: 'Ordenar',
        values: [
            { name: 'Recomendados', value: 'recommended' },
            { name: 'Precio más bajo', value: 'low-price' },
            { name: 'Precio más alto', value: 'high-price' },
        ]
    },
    'fr-FR': {
        name: 'Trier',
        values: [
            { name: 'Conseillé', value: 'recommended' },
            { name: 'Prix le plus bas', value: 'low-price' },
            { name: 'Prix le plus élevé', value: 'high-price' },
        ]
    },
    'it-IT': {
        name: 'Ordina',
        values: [
            { name: 'Consigliato', value: 'recommended' },
            { name: 'Prezzo più basso', value: 'low-price' },
            { name: 'Prezzo più alto', value: 'high-price' },
        ]
    },
    'de-DE': {
        name: 'Ordina',
        values: [
            { name: 'Consigliato', value: 'recommended' },
            { name: 'Prezzo più basso', value: 'low-price' },
            { name: 'Prezzo più alto', value: 'high-price' },
        ]
    },
};
const getFilters = (products = [], locale) => {
    if ((products === null || products === void 0 ? void 0 : products.length) === 0)
        return null;
    const finalFilters = {};
    if (locale) {
        finalFilters['sort'] = Object.assign(Object.assign({}, TRANSLATIONS_SORT_BY[locale]), { type: 'multiple' });
    }
    const mergeFilters = (filters) => {
        for (let filter of filters) {
            const { param, name, type, values } = filter;
            if (!(param in finalFilters)) {
                finalFilters[param] = type === 'multiple' ? { name, type, values } : { name, type };
            }
            else {
                if (type === 'multiple') {
                    const mergedValues = [...finalFilters[param].values, ...values];
                    // @ts-ignore
                    const uniqValues = [...new Map(mergedValues.map(item => [item.value, item])).values()];
                    finalFilters[param].values = Array.from(uniqValues);
                }
            }
        }
    };
    products.map((product) => {
        const { filters } = product;
        if ((filters === null || filters === void 0 ? void 0 : filters.length) > 0)
            mergeFilters(filters);
    });
    return [finalFilters, Object.keys(finalFilters)];
};
exports.getFilters = getFilters;
const updateActiveFilters = (currentFilters, filter) => {
    let incomingFilters = Object.assign({}, currentFilters);
    const param = incomingFilters[filter.param];
    if (filter.param === 'sort') {
        if (param && param[filter.value]) {
            delete incomingFilters[filter.param];
        }
        else {
            incomingFilters = Object.assign(Object.assign({}, incomingFilters), { [filter.param]: { [filter.value]: true } });
        }
    }
    else if (param) {
        if (filter.type === 'simple') {
            delete incomingFilters[filter.param];
        }
        else {
            param[filter.value] ? delete param[filter.value] : param[filter.value] = true;
        }
    }
    else {
        if (filter.type === 'simple') {
            incomingFilters[filter.param] = true;
        }
        else {
            incomingFilters[filter.param] = { [filter.value]: true };
        }
    }
    return incomingFilters;
};
exports.updateActiveFilters = updateActiveFilters;
const generateQueryParams = (filters) => {
    if (Object.keys(filters).length < 1)
        return {};
    const queryParams = {};
    let stringParams = '';
    for (const key in filters) {
        if (typeof filters[key] === 'boolean') {
            queryParams[key] = 'true';
            stringParams += `${stringParams.length > 1 ? '&' : ''}${key}=true`;
        }
        else {
            let values = [];
            for (const value in filters[key]) {
                values.push(`${value}`);
            }
            queryParams[key] = values;
            stringParams += `${stringParams.length > 1 ? '&' : ''}${key}=${values.join(',')}`;
        }
    }
    return { queryParams, stringParams };
};
exports.generateQueryParams = generateQueryParams;
const getFiltersFromQuery = (url, params) => {
    var _a, _b;
    const queryFilters = {};
    const urlParams = new URL(`https://frontend.rules${url}`).searchParams;
    queryFilters['sort'] = { [(_a = urlParams.get('sort')) !== null && _a !== void 0 ? _a : 'recommended']: true };
    for (const param of params) {
        const values = (_b = urlParams.get(param)) === null || _b === void 0 ? void 0 : _b.split(',');
        if (values) {
            if ((values === null || values === void 0 ? void 0 : values.length) === 1) {
                queryFilters[param] = values[0] === 'true' ? true : { [values[0]]: true };
            }
            else {
                for (const value of values) {
                    if (queryFilters[param]) {
                        queryFilters[param][value] = true;
                    }
                    else {
                        queryFilters[param] = { [value]: true };
                    }
                }
            }
        }
    }
    return queryFilters;
};
exports.getFiltersFromQuery = getFiltersFromQuery;
const slugMatch = (arr1, arr2) => {
    let arr = arr1;
    if (typeof arr1 === 'string')
        arr = [arr1];
    if (arr.length !== arr2.length)
        return false;
    for (let i = 0; i < arr.length; i++) {
        if (arr[i] !== arr2[i])
            return false;
    }
    return true;
};
exports.slugMatch = slugMatch;
const getFeaturedFilters = (activeFilters, filters) => {
    var _a;
    const incomingfeaturedFilters = [];
    for (const keyparam in activeFilters) {
        const filter = filters === null || filters === void 0 ? void 0 : filters[keyparam];
        if (filter) {
            for (const keyvalue in activeFilters[keyparam]) {
                const matchFilter = (_a = filter === null || filter === void 0 ? void 0 : filter.values) === null || _a === void 0 ? void 0 : _a.filter(value => value.value === keyvalue)[0];
                if (matchFilter && matchFilter.media)
                    incomingfeaturedFilters.push(Object.assign(Object.assign({}, matchFilter), { param: keyparam }));
            }
        }
    }
    return incomingfeaturedFilters;
};
exports.getFeaturedFilters = getFeaturedFilters;
