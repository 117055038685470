"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.themeProject = void 0;
exports.themeProject = {
    colors: {
        'white': '#FFFFFF',
        'black': '#000000',
        'darkgreen': '#274943',
        'transparent': 'transparent'
    },
    fonts: {
        // eslint-disable-next-line quotes
        'primaryFont': `'Domaine Display Regular', serif`,
        // eslint-disable-next-line quotes
        'secondaryFont': `'Work Sans', sans-serif`,
        // eslint-disable-next-line quotes
        'familyCode': `'monospace', monospace`,
        'size': 16,
        'lineHeight': 24 / 16,
        'headersize': 20,
        'headerLineHeight': 24 / 20
    },
    ease: 'cubic-bezier(.25, .1, .25, 1)'
};
