"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.home = void 0;
/* eslint-disable quotes */
const NEXT_PUBLIC_DY_CLIENT_URL = process.env.NEXT_PUBLIC_DY_CLIENT_URL;
const iluzione = {
    address: {
        "@type": "PostalAddress",
        "addressLocality": "Barcelona",
        "postalCode": "08021",
        "streetAddress": "Ferrán Agulló 18, Barcelona"
    },
    aggregateRating: {
        "@type": "AggregateRating",
        "ratingValue": "4.3",
    },
    name: "Iluzione",
    openingHours: [
        "Tu-Th 13:00-24:00",
        "Fr-Sa 13:00-01:00",
        "Saturdays 13:00-17:00"
    ],
    priceRange: "$$",
    servesCuisine: [
        "Mediterranean"
    ],
    telephone: "(+34) 935 418 856",
    url: "https://iluzione.com/",
    brand: "Iluzione",
};
const corso = {
    address: {
        "@type": "PostalAddress",
        "addressLocality": "La Roca Village",
        "postalCode": "08430",
        "streetAddress": "La Roca Village,Barcelona"
    },
    aggregateRating: {
        "@type": "AggregateRating",
        "ratingValue": "4",
    },
    name: "Corso Iluzione",
    openingHours: [
        "Mon-Sun 09:30- 22:00"
    ],
    priceRange: "$$",
    servesCuisine: [
        "Mediterranean"
    ],
    telephone: "(+34) 617 282 134",
    url: "https://corsoiluzione.com/",
    email: "corso@luzio.com",
    brand: "Corso iluzione",
};
const home = (data) => {
    let dataByUrl = NEXT_PUBLIC_DY_CLIENT_URL === 'https://iluzione.com/' ? iluzione : corso;
    return JSON.stringify(Object.assign(Object.assign({ "@context": "https://schema.org", "@type": "WebPage", "@id": "" }, ((dataByUrl === null || dataByUrl === void 0 ? void 0 : dataByUrl.url) && { "url": `${dataByUrl.url}` })), { "isPartOf": {
            "@type": "WebSite"
        }, "publisher": Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ "@type": "Restaurant" }, (data.seo && { "name": `${data.seo.title}` })), (data.seo && { "description": `${data.seo.description}` })), ((dataByUrl === null || dataByUrl === void 0 ? void 0 : dataByUrl.brand) && { "brand": `${dataByUrl.brand}` })), ((dataByUrl === null || dataByUrl === void 0 ? void 0 : dataByUrl.email) && { "email": `${dataByUrl.email}` })), ((dataByUrl === null || dataByUrl === void 0 ? void 0 : dataByUrl.url) && { "url": `${dataByUrl.url}` })), ((dataByUrl === null || dataByUrl === void 0 ? void 0 : dataByUrl.address) && { "address": `${dataByUrl.address}` })), ((dataByUrl === null || dataByUrl === void 0 ? void 0 : dataByUrl.aggregateRating) && { "aggregateRating": `${dataByUrl.aggregateRating}` })), ((dataByUrl === null || dataByUrl === void 0 ? void 0 : dataByUrl.name) && { "name": `${dataByUrl.name}` })), ((dataByUrl === null || dataByUrl === void 0 ? void 0 : dataByUrl.openingHours) && { "openingHours": `${dataByUrl.openingHours}` })), ((dataByUrl === null || dataByUrl === void 0 ? void 0 : dataByUrl.priceRange) && { "priceRange": `${dataByUrl.priceRange}` })), ((dataByUrl === null || dataByUrl === void 0 ? void 0 : dataByUrl.servesCuisine) && { "servesCuisine": `${dataByUrl.servesCuisine}` })), ((dataByUrl === null || dataByUrl === void 0 ? void 0 : dataByUrl.telephone) && { "telephone": `${dataByUrl.telephone}` })), { "sameAs": [
                "https://www.instagram.com/iluzione_restaurant/",
                "https://www.facebook.com/luzio",
                "https://twitter.com/luzio_",
                "https://www.linkedin.com/company/luzio",
            ], "image": "https://luzio.com/images/products/contents/sunpower.jpg", "contactPoint": [
                Object.assign(Object.assign({ "@type": "ContactPoint" }, ((dataByUrl === null || dataByUrl === void 0 ? void 0 : dataByUrl.email) && { "email": `${dataByUrl.email}` })), { "contactType": "customer service", "availableLanguage": ["English", "Spanish", "Catalan", "French"] })
            ], "logo": "https://luzio.com/images/logo.png", "currenciesAccepted": "EUR", "paymentAccepted": "Credit Card, Visa" }) }));
};
exports.home = home;
