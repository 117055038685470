"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCart = void 0;
const react_1 = require("react");
const context_1 = require("../context");
function useCart() {
    const ctx = (0, react_1.useContext)(context_1.CartContext);
    if (ctx === undefined)
        throw new Error('useCart must be used within a CartProvider');
    return ctx;
}
exports.useCart = useCart;
