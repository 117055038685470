import { useEffect, useState } from 'react'
import type { FC, MouseEvent, ReactNode } from 'react'
import { createPortal } from 'react-dom'
import styled, { css } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { slugify } from '@dy/commons/utils'
import { mq, vw, designGrid, hex2Rgba, grid, getP20 } from '@dy/commons/styles'

type xAlign = 'flex-start' | 'center' | 'flex-end' | 'right'
type size = 'full' | 'auto' | 's'
export interface iModal {
  title?: string,
  children: ReactNode,
  isActive: boolean,
  hasHeader?: boolean,
  isModalFilters?: boolean,
  zIndex?: number,
  readonly close: () => void,
  readonly xAlign?: xAlign,
  readonly variants?: (isActive:boolean) => {
    initial: { [key:string]: any },
    animate: { [key:string]: any },
    exit: { [key:string]: any },
    transition?: {
      duration: number,
      [key: string]: any
    }
  },
  readonly dialogStyles?: any,
  readonly onClickBack?: () => void
  readonly size?: size,
  className?: string
}

// VER DE SACAR el Aside como un overlay externo para minimizar el componente del Modal pero tiene que funcionar un self-justify para reemplazar al xAlign que tenemos ahora
const ModalStyled = styled(motion.aside) <{ $xAlign: xAlign, $zIndex, $isModalFilters }>`
  ${designGrid({})}
  align-items: flex-start;
  background-color: rgba(0, 0, 0, .2);
  bottom: 0;
  display: flex;
  height: 100dvh;
  height: 100vh;
  height: 100%;
  justify-content: ${({ $xAlign }) => $xAlign};
  left: 0;
  opacity: 0;
	outline: 0;
  overflow: auto;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  user-select: none;
  width: 100%;
  will-change: opacity;
  z-index: ${({ $zIndex }) => $zIndex ? $zIndex : 600};


  ${mq.greaterThan <{ $zIndex: number }> ('tablet')`
    z-index: ${({ $zIndex }) => $zIndex ? $zIndex : 600};
    
    &.just-mobile {
      display: none;
      opacity: 0;
      pointer-events: none;
      user-select: none;
      visibility: hidden;
    }
    
  `}

  &[aria-hidden='false'] {
    opacity: 1;
    pointer-events: auto;
    user-select: auto;
  }

  .modal__body {
    position: relative;
    width: 100%;
  }
`


const DialogStyled = styled(motion.div) <{ readonly $size?: size, $dialogStyles?: any, $xAlign?: xAlign, $isActive?: true | false }>`
  background-color: ${({ theme }) => `${theme.colors.white}`};
  box-shadow: 0 25px 50px rgba(0, 0, 0, .15);
  font-family: ${({ theme }) => theme.fonts.secondaryFont};
  grid-column: 1 / span 6;
  margin: ${({ $xAlign }) => $xAlign === 'right' ? '0 0 0 auto' : `110px auto ${vw(200, 'mobile')}`};
  max-width: ${({ $size }) => $size === 'full' ? 'unset' : $size === 's' ? '550px' : '767px'};
  position: relative;
  width: calc(100% - ${grid.mobile.columns.gutter.width}px);
  ${({ $isActive }) => !$isActive && css`
    user-select: none;
    pointer-events: none;
    > *,
    figure div {
      user-select: none;
      pointer-events: none;
    }
  `}

  ${mq.greaterThan<{ readonly $size?: size, $xAlign?: xAlign }>('tablet')`
    grid-column: 1 / span 12;
    margin: ${({ $xAlign }) => $xAlign === 'right' ? '0 0 0 auto' : `50px auto ${vw(200, 'desktop')}`};
    width: calc(100% - ${grid.tablet.columns.gutter.width}px);
    max-width: ${({ $size }) => $size === 's' ? vw(550, 'desktop'): 'unset'};
  `}

  ${mq.greaterThan<{ $xAlign?: xAlign }>('desktop')`
    margin: ${({ $xAlign }) => $xAlign === 'right' ? '0 0 0 auto' : '50px auto 200px'};
    width: 100%;
  `}

  ${mq.greaterThan<{ $xAlign?: xAlign }>('desktop-xs')`
    margin: ${({ $xAlign }) => $xAlign === 'right' ? '0 0 0 auto' : '50px auto 200px'};
    width: 100%;
  `}

  > header {
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => hex2Rgba(theme.colors.text, .1)};
    display: flex;
    height: ${vw(50, 'mobile')};
    justify-content: center;
    padding-left: ${vw(48, 'mobile')};
    position: relative;
    width: 100%;

    ${mq.greaterThan('tablet')`
      height: ${vw(50, 'desktop')};
      padding-left: ${vw(48, 'desktop')};
    `}

    ${mq.greaterThan('desktop')`
      height: 50px;
      padding-left: 48px;
    `}

    h2 {
      ${getP20}
      color: ${({ theme }) => theme.colors.red};
      font-weight: 500;
      margin-bottom: 0;
      width: 100%;
    }
  }

  ${({ $dialogStyles }) => $dialogStyles && $dialogStyles}
`


const defaultDialogVariants = (isActive: boolean) => ({
  initial: { y: '10px' },
  animate: { y: isActive ? 0 : '10px' },
  exit: { y: '10px' },
  transition: { duration: .3, delay: .1 }
})

export const Modal: FC<iModal> = ({ title = '', children, isActive, close, xAlign = 'center', variants = defaultDialogVariants, dialogStyles = null, size = 'auto',  zIndex = 600, isModalFilters = false, className = '' }) => {
  const [mounted, setMounted] = useState(false)
  const titleAsSlug = slugify(title)
  const onClick = (e:MouseEvent) => {
    if((e.target as HTMLElement).getAttribute('tabindex') === '-1') close()
  }

  useEffect(() => {
    setMounted(true)
    return () => setMounted(false)
  }, [])

  return (mounted ? createPortal(
    <AnimatePresence>
      <ModalStyled tabIndex={-1} aria-hidden={!isActive} initial={{ opacity: 0 }} animate={{ opacity: isActive ? 1 : 0 }} exit={{ opacity: 0 }} transition={{ duration: 0.3 }} onClick={onClick} $xAlign={xAlign} $zIndex={zIndex} $isModalFilters={isModalFilters} className={className}>
        <DialogStyled $size={size} role='dialog' aria-modal='true' aria-labelledby={titleAsSlug} {...variants(isActive)} {...(dialogStyles && { $dialogStyles: dialogStyles })} $xAlign={xAlign} $isActive={isActive}>
          {children}
        </DialogStyled>
      </ModalStyled>
    </AnimatePresence>
    , document.body) : null
  )
}
