"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSeasonSchedule = void 0;
const dates = {
    startSummerStr: {
        'corso': {
            start: '2021/06/16',
            ends: '2021/09/10'
        },
        'iluzione': {
            start: '2021/08/1',
            ends: '2021/9/27'
        }
    }
};
const getSeasonSchedule = (restaurant) => {
    const startSummerStr = dates.startSummerStr[restaurant].start;
    const summerEndsStr = dates.startSummerStr[restaurant].ends;
    const now = new Date();
    const dateNow = now.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric' });
    const [day, month] = dateNow.split('/');
    const startDate = new Date(startSummerStr);
    const startDateNow = startDate.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric' });
    const [dayStart, monthStart] = startDateNow.split('/');
    const endDate = new Date(summerEndsStr);
    const endDateNow = endDate.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric' });
    const [dayEnds, monthEnds] = endDateNow.split('/');
    if ((+month >= +monthStart && +month <= +monthEnds) && (+day >= +dayStart && +day <= +dayEnds))
        return 'summer';
    else
        return 'winter';
};
exports.getSeasonSchedule = getSeasonSchedule;
