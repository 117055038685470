import { css } from 'styled-components'
import { mq, vw } from '@dy/commons/styles'
import styled from 'styled-components'

export const dialogStyles = css`
  background: ${({ theme }) => theme.colors.darkgreen};
  display: flex;
  flex-direction: column;
  justify-content:flex-end;
  margin: 0;
  min-height: 100%;
  padding: ${vw(120, 'mobile')} ${vw(17, 'mobile')} ${vw(25, 'mobile')};
  width: 100%;

  ${mq.greaterThan('tablet')`
    max-width: ${vw(400, 'desktop')};
    width: auto;
  `}

  ${mq.greaterThan('desktop')`
    max-width: 400px;
  `}
`

export const Wrapper = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${vw(40, 'mobile')};
`
