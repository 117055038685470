"use strict";
// PENDING improve locale on base file instead of here since its a general type
// type TypeLocale = 'en-EU' | 'es-ES' | 'es-CT' | 'es-CN' | 'es-MD' | 'en-GB' | 'fr-FR' | 'de-DE'
// type TypeLocaleCountry = 'es-ES' | 'en-GB' | 'fr-FR' | 'de-DE'
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRegion = exports.getLang = exports.getLocaleCountry = void 0;
// export function getLocaleCountry(locale: TypeLocale): TypeLocaleCountry {
function getLocaleCountry(locale) {
    switch (locale) {
        case 'es-CN':
        case 'es-MD':
        case 'es-CT':
            return 'es-ES';
        case 'en-EU':
            return 'en-GB';
        default:
            return locale;
    }
}
exports.getLocaleCountry = getLocaleCountry;
const NEXT_PUBLIC_VERCEL = process.env.NEXT_PUBLIC_VERCEL;
function getLang(str, path) {
    var _a, _b;
    if (NEXT_PUBLIC_VERCEL === 'true') {
        if (typeof str !== 'string')
            throw new Error('getLang parameter must be typeof "string"');
        return str.substring(2, 0);
    }
    else if ((typeof path !== 'undefined' || path !== 'string')) {
        if ((_a = path === null || path === void 0 ? void 0 : path.split('/')) === null || _a === void 0 ? void 0 : _a[1])
            return (_b = path === null || path === void 0 ? void 0 : path.split('/')) === null || _b === void 0 ? void 0 : _b[1];
        return 'es';
    }
    else {
        return 'es';
    }
}
exports.getLang = getLang;
function getRegion(str) {
    if (typeof str !== 'string')
        throw new Error('getRegion parameter must be typeof "string"');
    return str.split('-')[1];
}
exports.getRegion = getRegion;
// VERCEL env variable to know if the project gonna live in vercel or not
// locale defined -> proyecto Vercel con i18n
// locale undefined en proyecto Vercel -> Throw Error
// proyecto static ->
// locale defined or try to get 'locale' through pathname split
// if also undefined ? then Default locale in the .env.file
