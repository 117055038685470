import { memo, useMemo } from 'react'
import type { FC } from 'react'
import type { NextPage } from 'next'
import { getTranslations } from '@/context'
import { HeadTag } from '@dy/commons/components'
import { AnimatePresence } from 'framer-motion'
import styled from 'styled-components'
import { HeaderRestaurant, Footer } from '@dy/commons/components'
import { Main } from 'components/layout'
import { vw, mq, getP20_1, pxTorem } from '@dy/commons/styles'
import { useTranslator } from '@/hooks'
import { getLang, getSeasonSchedule } from '@dy/commons/utils'
import { useRouter } from 'next/router'

type TypeHomePage = {
  data: any,
  translations?: any
}

const InfoWrapper = styled.div`
  width: 75%;

  ${mq.greaterThan('container')`
    width: 100%;
  `}

  p,a,span {
    ${getP20_1}

    @media screen and (min-width: 768px) and (max-width: 1170px) {
      font-size: ${pxTorem(20)};
      line-height: ${pxTorem(24)};
    }
    ${mq.greaterThan('container')`
      font-size: 20px;
      line-height: 24px;
    `}
  }

  div:nth-child(2),
  div:nth-child(3){
    
    ${mq.lessThan('tablet')`
      margin-top: ${vw(30, 'mobile')};
    `}

    ${mq.greaterThan('tablet')`
      margin-top: ${vw(30, 'desktop')};
    `}

     ${mq.greaterThan('container')`
      margin-top: 24.5px;
    `}
  }

  a {
    align-items: center;
    display: flex;
    flex-direction: row;
    svg {
      display: block;
      
      ${mq.lessThan('tablet')`
        height:${vw(24, 'mobile')};
        margin-left:${vw(5, 'mobile')};
        width:${vw(24, 'mobile')};
      `}
      
      ${mq.greaterThan('tablet')`
        height:${vw(24, 'desktop')};
        margin-left:${vw(5, 'desktop')};
        width:${vw(24, 'desktop')};
      `}
      
      ${mq.greaterThan('container')`
        height: 19.47px;
        margin-left: 4.05px;
        width: 19.47px;
      `}
    }

    &:hover{
      svg{
        stroke-width:2;
        transform:translateX(${vw(5, 'mobile')});
        transition: transform 600ms ease-in-out, stroke-width 600ms ease-in-out;

        ${mq.greaterThan('tablet')`
          transform:translateX(${vw(5, 'desktop')});
        `}
      }
    }

  }

  div:nth-child(3) {
    display: block;

    ${mq.greaterThan('tablet')`
      display: none;
    `}
  }
  .tiktok {
    display: flex;

    ${mq.greaterThan('tablet')`
      display: none;
    `}
  }

`

export async function getStaticProps({ locale }) {
  const routerLocale = getLang(locale)
  const dictionary = getTranslations(routerLocale, ['layout'])

  return {
    props: {
      data: {
        seo: {
          es:{
            title: 'Restaurante ILUZIONE Barcelona',
            description: 'Restaurante italiano con alma neoyorquina en un antiguo almacén del Turó Park, dentro de la exclusiva tienda de decoración Luzio Concept Store.'
          },
          ca:{
            title: 'Restaurant ILUZIONE Barcelona',
            description: 'Restaurant italià amb ànima neoyorkina situat a un antic magatzem del Turó Park, dins de l’exclusiva botiga de decoració Luzio Concept Store.'
          },
          en:{
            title: 'ILUZIONE Barcelona Restaurant',
            description: 'Italian restaurant inspired in New York located in a former warehouse at Turó Park, inside the exclusive decoration boutique Luzio Concept Store'
          }
        }
      },
      translations: dictionary
    }
  }
}

export const getFooterInfo = (t, seasonInfo) => {
  return (
    <InfoWrapper>
      { seasonInfo === 'summer' ?
        <div>
          <p>{t('layout.footer.weekdays_summer')}</p>
          <p>{t('layout.footer.weekends_summer')}</p>
          <p>{t('layout.footer.sunday_summer')}</p>
          <p>{t('layout.footer.closed_summer')}</p>
        </div>
        :
        <div>
          <p>{t('layout.footer.weekdays_winter')}</p>
          <p>{t('layout.footer.weekends_winter')}</p>
          <p>{t('layout.footer.sunday_winter')}</p>
          <p>{t('layout.footer.closed_winter')}</p>
        </div>

      }
      <div>
        <a href='https://goo.gl/maps/RcKV1DoZq9V6hrRs8'>
          <span>{t('layout.footer.direction')}</span>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.95001 4.08004L15.47 10.6C16.24 11.37 16.24 12.63 15.47 13.4L8.95001 19.92" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </a>
        <a href={'tel:+34935418856'}>
          <span>{t('layout.footer.phone_number')}</span>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.95001 4.08004L15.47 10.6C16.24 11.37 16.24 12.63 15.47 13.4L8.95001 19.92" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </a>
      </div>
      <div>
        <a href={'https://www.instagram.com/iluzione_restaurant/'}>
          <span>Instagram</span>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.95001 4.08004L15.47 10.6C16.24 11.37 16.24 12.63 15.47 13.4L8.95001 19.92" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </a>
      </div>
      <div>
        <a href={'https://www.tiktok.com/@iluzione_restaurant'} className='tiktok'>
          <span>TikTok</span>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.95001 4.08004L15.47 10.6C16.24 11.37 16.24 12.63 15.47 13.4L8.95001 19.92" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </a>
      </div>
    </InfoWrapper>
  )
}

const HomePage: NextPage<TypeHomePage> & { Page?: FC } = memo(({ data }) => {
  const { seo } = data
  const { locale } = useRouter()
  const { t } = useTranslator()
  const seasonInfo = getSeasonSchedule('iluzione')
  const FooterInfo = useMemo(() => getFooterInfo(t, seasonInfo), [t, seasonInfo])

  return (
    <>
      <HeadTag seo={seo[locale]} jsonld={{ data, pageType: 'home' }}/>
      <AnimatePresence>
        <HeaderRestaurant restaurant='iluzione' />
        <Main/>
        <Footer web={'iluzione'}>
          {FooterInfo}
        </Footer>
      </AnimatePresence>
    </>
  )
})

export default HomePage
