"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Fieldset = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const styled_components_1 = __importStar(require("styled-components"));
const styles_1 = require("../styles");
const loading = (0, styled_components_1.keyframes) `
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 100%;
  }
`;
const FieldsetStyled = styled_components_1.default.fieldset `
  width: 100%;

  ${styles_1.mq.greaterThan('tablet') `
    width: ${({ col }) => 100 * col / 12}%;
  `}


  &[disabled] {
    opacity: .5;
  }

  &[aria-busy='true'] {
    opacity: .6;
  }

  legend {
    ${styles_1.getP20v2}
    font-weight: 400;
    margin-bottom: ${(0, styles_1.vw)(20, 'mobile')};
    padding: 0 ${(0, styles_1.vw)(4.25, 'mobile')};
    width: 100%;

    ${styles_1.mq.greaterThan('tablet') `
      margin-bottom: ${(0, styles_1.vw)(20, 'desktop')};
      padding: 0 ${(0, styles_1.vw)(12.5, 'desktop')};
    `}

    ${styles_1.mq.greaterThan('desktop') `
      margin-bottom: 20px;
      padding: 0 12.5px;
    `}
  }

  .fieldset-content-wrapper {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0 0 ${(0, styles_1.vw)(50, 'desktop')};
    width: 100%;

    ${styles_1.mq.greaterThan('tablet') `
      padding: 0 0 ${(0, styles_1.vw)(50, 'desktop')};
    `}

    ${styles_1.mq.greaterThan('desktop') `
      padding: 0 0 50px;
    `}
  }
`;
const Fieldset = ({ col = 12, children, disabled = false, ariaBusy = false, legend }) => {
    return ((0, jsx_runtime_1.jsxs)(FieldsetStyled, Object.assign({ col: col, disabled: disabled, "aria-busy": ariaBusy }, { children: [legend && (0, jsx_runtime_1.jsx)("legend", { children: legend }), (0, jsx_runtime_1.jsx)("div", Object.assign({ className: 'fieldset-content-wrapper' }, { children: children }))] })));
};
exports.Fieldset = Fieldset;
