import { memo, useMemo } from 'react'
import type { FC } from 'react'
import { useModal } from '@dy/commons/hooks'
import { MODALS } from '@dy/commons/context'
import { Modal } from '..'
import { dialogStyles, Wrapper } from './modalMobileMenuStyles'
import { dialogVariants } from './modalMobileMenuVariants'
import { useTranslator } from '@/hooks'
import { getFooterInfo } from 'pages'
import { getFooterLinks } from '@dy/commons/components'
import { useRouter } from 'next/router'
import { getLang, getSeasonSchedule } from '@dy/commons/utils'

type MenuProps = {}

export const ModalMenu: FC<MenuProps> = memo(() => {
  const { isActive, toggle } = useModal(MODALS.MENU)
  const { t } = useTranslator()
  const { locale:routerLocale, pathname } = useRouter()
  const locale = getLang(routerLocale, pathname)
  const seasonInfo = getSeasonSchedule('iluzione')
  const FooterInfo = useMemo(() => getFooterInfo(t, seasonInfo), [t, seasonInfo])
  const FooterLinks = useMemo(() => getFooterLinks('iluzione', locale), [locale])

  return(
    <Modal isActive={isActive} close={toggle} title='Menu' xAlign='flex-start' variants={dialogVariants} dialogStyles={dialogStyles} hasHeader={false} zIndex={95} isModalFilters={true} className={'just-mobile'}>
      {FooterInfo}
      <Wrapper>
        {FooterLinks}
      </Wrapper>
    </Modal>
  )
})
