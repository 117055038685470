"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDeviceType = void 0;
const react_1 = require("react");
const BREAKPOINTS = {
    mobile: 375,
    tablet: 768,
    desktopXs: 992,
    desktop: 1440,
    getDeviceType(width) {
        return width < this.tablet ? 'mobile' :
            width < this.desktopXs ? 'tablet' :
                'desktop';
    }
};
const checkUserAgent = () => {
    const UA = navigator.userAgent;
    return UA ? Boolean(UA.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i)) : false;
};
const initialInfo = {
    size: { width: undefined, height: undefined },
    deviceType: 'desktop',
    isMobile: false,
    mobileDevice: false,
    mobileLandscape: false,
    isTouchDevice: false
};
const getInfo = (isClient) => {
    var _a;
    return isClient ? {
        size: { width: window.innerWidth, height: window.innerHeight },
        deviceType: BREAKPOINTS.getDeviceType(window.innerWidth),
        isMobile: BREAKPOINTS.getDeviceType(window.innerWidth) === 'mobile',
        mobileDevice: checkUserAgent(),
        mobileLandscape: ((_a = screen === null || screen === void 0 ? void 0 : screen.orientation) === null || _a === void 0 ? void 0 : _a.angle) === 90,
        isTouchDevice: (('ontouchstart' in window) || (navigator.maxTouchPoints > 0))
    } : initialInfo;
};
function debounce(func, miliseconds) {
    let timer;
    return function (event) {
        if (timer)
            clearTimeout(timer);
        timer = setTimeout(func, miliseconds, event);
    };
}
const useDeviceType = (miliseconds = 20) => {
    const [info, setInfo] = (0, react_1.useState)(getInfo(typeof window !== 'undefined'));
    const handleResize = (0, react_1.useCallback)(() => {
        const newInfo = getInfo(typeof window !== 'undefined');
        setInfo(newInfo);
    }, [setInfo]);
    const debouncedHandler = debounce(handleResize, miliseconds);
    (0, react_1.useEffect)(() => {
        window.addEventListener('resize', debouncedHandler, { passive: true });
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', debouncedHandler);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return info;
};
exports.useDeviceType = useDeviceType;
