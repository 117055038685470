"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.H6 = exports.H5 = exports.H4 = exports.H3 = exports.H2 = exports.H1 = exports.getP20v2 = exports.getP12_1 = exports.getP12 = exports.getP15_2 = exports.getP15_1B = exports.getP15_1 = exports.getP15 = exports.getP16_1b = exports.getP16 = exports.getP18_1B = exports.getP18_1 = exports.getP20_1 = exports.getP20 = exports.getP30 = exports.getP40 = exports.getP50 = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const _1 = require(".");
const _2 = require(".");
// 1rem mobile -> 15px, 1rem desktop -> 18px
const rem = {
    baseMobile: 15,
    base: 18,
    get: (value, factor) => `${value / factor}rem`
};
const getP50 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(50, rem.baseMobile)};
    font-weight: regular;
    line-height: ${rem.get(54, rem.baseMobile)};
    letter-spacing: -3.81px;
    font-family: ${_2.themeProject.fonts.secondaryFont};

    ${_1.mq.greaterThan('tablet') `
      font-size: ${rem.get(50, rem.base)};
      line-height: ${rem.get(54, rem.base)};
      letter-spacing: -3.81px;
    `}
  `;
};
exports.getP50 = getP50;
const getP40 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(40, rem.baseMobile)};
    font-weight: 300;
    line-height: ${rem.get(46.92, rem.baseMobile)};
    letter-spacing: -0.4px;
    font-family: ${_2.themeProject.fonts.secondaryFont};

    ${_1.mq.greaterThan('tablet') `
      font-size: ${rem.get(40, rem.base)};
      line-height: ${rem.get(46.92, rem.base)};
    `}
  `;
};
exports.getP40 = getP40;
const getP30 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(20, rem.baseMobile)};
    font-weight: 700;
    line-height: ${rem.get(24, rem.baseMobile)};
    letter-spacing: -0.2px;

    ${_1.mq.greaterThan('tablet') `
      font-size: ${rem.get(30, rem.base)};
      line-height: ${rem.get(42, rem.base)};
      letter-spacing: -0.3px;
    `}
  `;
};
exports.getP30 = getP30;
const getP20 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(20, rem.baseMobile)};
    font-weight: 400;
    line-height: ${rem.get(26.2, rem.baseMobile)};
    letter-spacing: 0px;
    font-family: ${_2.themeProject.fonts.primaryFont};

    ${_1.mq.greaterThan('tablet') `
      font-size: ${rem.get(20, rem.base)};
      line-height: ${rem.get(26.2, rem.base)};
      letter-spacing: 0px;
    `}
  `;
};
exports.getP20 = getP20;
const getP20_1 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(20, rem.baseMobile)};
    font-weight: 400;
    line-height: ${rem.get(24, rem.baseMobile)};
    letter-spacing: 0px;
    font-family: ${_2.themeProject.fonts.primaryFont};

    ${_1.mq.greaterThan('tablet') `
      font-size: ${rem.get(20, rem.base)};
      line-height: ${rem.get(24, rem.base)};
      letter-spacing: 0px;
    `}
  `;
};
exports.getP20_1 = getP20_1;
const getP18_1 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(15, rem.baseMobile)};
    font-weight: 300;
    line-height: ${rem.get(19, rem.baseMobile)};
    letter-spacing: -0.3px;
    font-family: ${_2.themeProject.fonts.secondaryFont};

    ${_1.mq.greaterThan('tablet') `
      font-size: ${rem.get(18, rem.base)};
      line-height: ${rem.get(22, rem.base)};
      letter-spacing: -0.36px;
    `}
  `;
};
exports.getP18_1 = getP18_1;
const getP18_1B = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(15, rem.baseMobile)};
    font-weight: 500;
    line-height: ${rem.get(19, rem.baseMobile)};
    letter-spacing: -0.3px;
    font-family: ${_2.themeProject.fonts.secondaryFont};

    ${_1.mq.greaterThan('tablet') `
      font-size: ${rem.get(18, rem.base)};
      font-weight: 700;
      line-height: ${rem.get(22, rem.base)};
      letter-spacing: 0px;
    `}
  `;
};
exports.getP18_1B = getP18_1B;
const getP16 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(16, rem.baseMobile)};
    font-weight: 400;
    line-height: ${rem.get(19, rem.baseMobile)};
    letter-spacing: 0px;
    font-family: ${_2.themeProject.fonts.secondaryFont};

    ${_1.mq.greaterThan('tablet') `
      font-size: ${rem.get(18, rem.base)};
      line-height: ${rem.get(21, rem.base)};
      letter-spacing: 0;
    `}
  `;
};
exports.getP16 = getP16;
const getP16_1b = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(16, rem.baseMobile)};
    font-weight: 600;
    line-height: ${rem.get(19, rem.baseMobile)};
    letter-spacing: 0px;
    font-family: ${_2.themeProject.fonts.secondaryFont};

    ${_1.mq.greaterThan('tablet') `
      font-size: ${rem.get(18, rem.base)};
      line-height: ${rem.get(21, rem.base)};
      letter-spacing: 0;
    `}
  `;
};
exports.getP16_1b = getP16_1b;
const getP15 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(12, rem.baseMobile)};
    font-weight: 700;
    line-height: ${rem.get(15, rem.baseMobile)};
    letter-spacing: 0.13px;

    ${_1.mq.greaterThan('tablet') `
      font-size: ${rem.get(15, rem.base)};
      line-height: ${rem.get(21, rem.base)};
      letter-spacing: 0.17px;
    `}
  `;
};
exports.getP15 = getP15;
const getP15_1 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(12, rem.baseMobile)};
    font-weight: light;
    line-height: ${rem.get(15, rem.baseMobile)};
    letter-spacing: -0.4px;
    font-family: ${_2.themeProject.fonts.secondaryFont};

    ${_1.mq.greaterThan('tablet') `
      font-size: ${rem.get(15, rem.base)};
      line-height: ${rem.get(20, rem.base)};
      letter-spacing: -0.5px;
    `}
  `;
};
exports.getP15_1 = getP15_1;
const getP15_1B = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(15, rem.baseMobile)};
    font-weight: 300;
    line-height: ${rem.get(15, rem.baseMobile)};
    letter-spacing: -0.4px;
    font-family: ${_2.themeProject.fonts.secondaryFont};

    ${_1.mq.greaterThan('tablet') `
      font-size: ${rem.get(15, rem.base)};
      line-height: ${rem.get(20, rem.base)};
      letter-spacing: -0.5px;
    `}
  `;
};
exports.getP15_1B = getP15_1B;
const getP15_2 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(15, rem.baseMobile)};
    font-weight: 300;
    line-height: ${rem.get(14, rem.baseMobile)};
    letter-spacing: -.38px;
    font-family: ${_2.themeProject.fonts.secondaryFont};

    ${_1.mq.greaterThan('tablet') `
      font-size: ${rem.get(15, rem.base)};
      line-height: ${rem.get(14, rem.base)};
    `}
  `;
};
exports.getP15_2 = getP15_2;
const getP12 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(9, rem.baseMobile)};
    font-weight: 700;
    line-height: ${rem.get(15, rem.baseMobile)};
    letter-spacing: 0.1px;

    ${_1.mq.greaterThan('tablet') `
      font-size: ${rem.get(12, rem.base)};
      line-height: ${rem.get(21, rem.base)};
      letter-spacing: 0.13px;
    `}
  `;
};
exports.getP12 = getP12;
const getP12_1 = () => {
    return (0, styled_components_1.css) `
    font-size: ${rem.get(10, rem.baseMobile)};
    font-weight: 300;
    line-height: ${rem.get(13, rem.baseMobile)};
    letter-spacing: -0.33px;
    font-family: ${_2.themeProject.fonts.secondaryFont};

    ${_1.mq.greaterThan('tablet') `
      font-size: ${rem.get(12, rem.base)};
      line-height: ${rem.get(14, rem.base)};
      letter-spacing: -0.3px;
    `}
  `;
};
exports.getP12_1 = getP12_1;
exports.getP20v2 = (0, styled_components_1.css) `
  font-size: ${rem.get(20, rem.base)};
  line-height: ${rem.get(24, rem.base)};
  letter-spacing: .5px;

  ${_1.mq.greaterThan('tablet') `
    font-size: ${rem.get(20, rem.base)};
    line-height: ${rem.get(24, rem.base)};
    letter-spacing: .5px;
  `}
`;
exports.H1 = styled_components_1.default.h1 `
  ${(0, exports.getP50)()}
`;
exports.H2 = styled_components_1.default.h2 `
  ${(0, exports.getP30)()}
`;
exports.H3 = styled_components_1.default.h3 `
  ${(0, exports.getP20)()}
`;
exports.H4 = styled_components_1.default.h4 `
  ${(0, exports.getP18_1)()}
`;
exports.H5 = styled_components_1.default.h5 `
  ${(0, exports.getP15)()}
`;
exports.H6 = styled_components_1.default.h6 `
  ${(0, exports.getP12_1)()}
`;
