"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parserMedia = void 0;
const parserMedia = (data) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    if ((data === null || data === void 0 ? void 0 : data.type) === 'svg') {
        return ({
            type: data.type,
            alt: data.alt,
            default: {
                src: data.url
            }
        });
    }
    if (!(data === null || data === void 0 ? void 0 : data.default))
        return data;
    const media = {
        type: data.type,
        alt: data.alt,
        default: {
            src: (_c = (_b = (_a = data === null || data === void 0 ? void 0 : data.default) === null || _a === void 0 ? void 0 : _a['2x']) === null || _b === void 0 ? void 0 : _b.regular) !== null && _c !== void 0 ? _c : (_e = (_d = data === null || data === void 0 ? void 0 : data.default) === null || _d === void 0 ? void 0 : _d['2x']) === null || _e === void 0 ? void 0 : _e.compressed,
            size: {
                height: (_g = (_f = data === null || data === void 0 ? void 0 : data.default) === null || _f === void 0 ? void 0 : _f.size) === null || _g === void 0 ? void 0 : _g.height,
                width: (_j = (_h = data === null || data === void 0 ? void 0 : data.default) === null || _h === void 0 ? void 0 : _h.size) === null || _j === void 0 ? void 0 : _j.width,
            }
        }
    };
    return media;
};
exports.parserMedia = parserMedia;
