"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pushAnalyticsEvent = void 0;
const getSelectItemObject = (data) => {
    var _a;
    const { name, brand, categories, sku, variant, idx, ga_list, locale } = data;
    return {
        'event': 'select_item',
        'ecommerce': {
            'items': [Object.assign(Object.assign({ 'item_name': name, 'item_id': variant ? variant.sku : sku, 'discount': variant.prices.retail_price_inc_tax > 0 ? (variant.prices.retail_price_inc_tax - variant.prices.price_inc_tax) : 0, 'index': idx, 'item_list_name': ga_list.name, 'item_list_id': ga_list.id, 'affiliation': `Luzio ${locale} Store`, 'item_brand': (_a = brand === null || brand === void 0 ? void 0 : brand.name) !== null && _a !== void 0 ? _a : 'Packs' }, (categories && { 'item_category2': categories[categories.length - 1][locale] })), { 'item_variant': variant.name, 'price': variant.prices.price_inc_tax, 'currency': 'EUR', 'quantity': 1 })],
            'item_list_name': ga_list.name,
            'item_list_id': ga_list.id
        }
    };
};
const getViewItemObject = (data) => {
    var _a;
    const { name, brand, categories, sku, variant, idx, ga_list, isPDP } = data;
    return {
        'event': 'view_item',
        'ecommerce': {
            'items': [Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ 'item_name': name, 'item_id': isPDP ? sku : variant.sku, 'price': variant.prices.price_inc_tax, 'item_brand': (_a = brand === null || brand === void 0 ? void 0 : brand.name) !== null && _a !== void 0 ? _a : 'Packs' }, (categories && { 'item_category': categories[0].name })), { 'item_variant': variant.name }), (ga_list && {
                    'item_list_name': ga_list.name,
                    'item_list_id': ga_list.id
                })), (idx && { 'index': idx })), { 'quantity': 1 })]
        }
    };
};
const getViewItemListObject = (data) => {
    const { products, ga_list } = data;
    const items = products.map((product, idx) => {
        var _a;
        const { name, brand, categories, variant } = product;
        return Object.assign(Object.assign({ 'item_name': name, 'item_id': variant.sku, 'price': variant.prices.price_inc_tax, 'item_brand': (_a = brand === null || brand === void 0 ? void 0 : brand.name) !== null && _a !== void 0 ? _a : 'Packs' }, (categories && { 'item_category': categories[0].name })), { 'item_variant': variant.name, 'item_list_name': ga_list.name, 'item_list_id': ga_list.id, 'index': idx + 1, 'quantity': 1 });
    });
    return {
        'event': 'view_item_list',
        'ecommerce': {
            'items': items
        }
    };
};
const getCartItemObject = (data, add = true) => {
    var _a;
    const { name, brand, categories, variant, units } = data;
    return {
        'event': add ? 'add_to_cart' : 'remove_from_cart',
        'ecommerce': {
            'items': Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ 'item_name': name, 'item_id': variant.sku, 'price': variant.prices.price_inc_tax, 'item_brand': (_a = brand === null || brand === void 0 ? void 0 : brand.name) !== null && _a !== void 0 ? _a : 'Packs' }, (categories && { 'item_category': categories[0].name })), { 'item_variant': variant.name }), (variant.ga_list && {
                'item_list_name': variant.ga_list.name,
                'item_list_id': variant.ga_list.id
            })), (variant.idx && { 'index': variant.idx })), { 'quantity': units !== null && units !== void 0 ? units : 1 })
        }
    };
};
// Data from BC
const getCheckoutObj = (data) => {
    const items = data.line_items.physical_items.map((item) => {
        const { name, extended_sale_price, sku, variant_id, quantity } = item;
        return {
            'item_name': name,
            'item_id': sku,
            'price': extended_sale_price,
            'item_variant': variant_id,
            'quantity': quantity
        };
    });
    return {
        'event': 'begin_checkout',
        'ecommerce': {
            'items': items
        }
    };
};
// Data from BC
const getPurchaseObj = (data) => {
    var _a;
    const { id, cart_amount, locale, line_items, currency } = data;
    const items = line_items.physical_items.map((item) => {
        const { name, extended_sale_price, sku, variant_id, quantity } = item;
        return {
            'item_name': name,
            'item_id': sku,
            'price': extended_sale_price,
            'item_variant': variant_id,
            'quantity': quantity
        };
    });
    return {
        'event': 'purchase',
        'ecommerce': {
            'transaction_id': id,
            'affiliation': `Luzio ${locale} Store`,
            'value': cart_amount,
            // 'tax': '4.90',
            // 'shipping': '5.99',
            'currency': (_a = currency === null || currency === void 0 ? void 0 : currency.code) !== null && _a !== void 0 ? _a : 'EUR',
            'items': items
        }
    };
};
const events = {
    'view_item_list': (data) => getViewItemListObject(data),
    'select_item': (data) => getSelectItemObject(data),
    'view_item': (data) => getViewItemObject(data),
    'add_to_cart': (data) => getCartItemObject(data, true),
    'remove_from_cart': (data) => getCartItemObject(data, false),
    'begin_checkout': (data) => getCheckoutObj(data),
    'purchase': (data) => getPurchaseObj(data),
};
const pushAnalyticsEvent = (event_type, data) => {
    if (typeof window !== 'undefined') {
        window['dataLayer'] = window['dataLayer'] || [];
        window['dataLayer'].push({ ecommerce: null });
        window['dataLayer'].push(events[event_type](data));
    }
};
exports.pushAnalyticsEvent = pushAnalyticsEvent;
