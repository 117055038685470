"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MediaContextProvider = exports.Media = exports.mediaStyles = void 0;
const fresnel_1 = require("@artsy/fresnel");
const MediaQueries = (0, fresnel_1.createMedia)({
    breakpoints: {
        mobile: 0,
        tablet: 768,
        laptop: 996,
        desktop: 1200,
        screen: 1440,
    }
});
// Make styles for injection into the header of the page
exports.mediaStyles = MediaQueries.createMediaStyle();
exports.Media = MediaQueries.Media, exports.MediaContextProvider = MediaQueries.MediaContextProvider;
