"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calcPriceWithTax = exports.currencyFormatter = exports.currencyBasedOnRegion = void 0;
const locales_1 = require("./locales");
exports.currencyBasedOnRegion = {
    'AD': { code: 'EUR' },
    'AL': { code: 'EUR' },
    'AS': { code: 'USD' },
    'AT': { code: 'EUR' },
    'AU': { code: 'AUD' },
    'BQ': { code: 'USD' },
    'CH': { code: 'EUR' },
    'DE': { code: 'EUR' },
    'DEU': { code: 'EUR' },
    'EC': { code: 'USD' },
    'ES': { code: 'EUR' },
    'ESP': { code: 'EUR' },
    'FM': { code: 'USD' },
    'FR': { code: 'EUR' },
    'FRA': { code: 'EUR' },
    'GB': { code: 'GBP' },
    'GBR': { code: 'GBP' },
    'GR': { code: 'EUR' },
    'GU': { code: 'USD' },
    'HT': { code: 'USD' },
    'IE': { code: 'EUR' },
    'IM': { code: 'GBP' },
    'IO': { code: 'USD' },
    'IT': { code: 'EUR' },
    'ITA': { code: 'EUR' },
    'JE': { code: 'GBP' },
    'MC': { code: 'EUR' },
    'MH': { code: 'USD' },
    'MP': { code: 'USD' },
    'PA': { code: 'USD' },
    'PR': { code: 'USD' },
    'PT': { code: 'EUR' },
    'PW': { code: 'USD' },
    'SE': { code: 'EUR' },
    'SV': { code: 'EUR' },
    'UM': { code: 'USD' },
    'US': { code: 'USD' },
};
const currencyFormatter = ({ price, localeRegion, decimals = 2, round = true, trailingZeros = false }) => {
    var _a, _b;
    const region = (0, locales_1.getLocaleCountry)(localeRegion).split('-')[1];
    const digits = (!round && price % 1 !== 0) ? decimals : trailingZeros ? decimals : 0;
    return new Intl.NumberFormat(localeRegion, {
        style: 'currency',
        currency: (_b = (_a = exports.currencyBasedOnRegion === null || exports.currencyBasedOnRegion === void 0 ? void 0 : exports.currencyBasedOnRegion[region]) === null || _a === void 0 ? void 0 : _a.code) !== null && _b !== void 0 ? _b : 'EUR',
        currencyDisplay: 'narrowSymbol',
        minimumFractionDigits: digits,
        maximumFractionDigits: digits
    }).format(round ? Math.round(price) : price);
};
exports.currencyFormatter = currencyFormatter;
const calcPriceWithTax = ({ price, tax }) => {
    if (!price || typeof price !== 'number')
        return null;
    const vat = typeof tax === 'number' ? tax : 0;
    const taxAsValue = price * vat / 100;
    return Math.round(price + taxAsValue);
};
exports.calcPriceWithTax = calcPriceWithTax;
