import type { AppProps, NextWebVitalsMetric } from 'next/app'
import { ThemeProvider }  from 'styled-components'
import { TranslationsProvider } from '@/context'
import { ModalsProvider } from '@dy/commons/context'
import { theme, Reset } from '@dy/commons/styles'
import { Layout } from '@/components'
import Script from 'next/script'

import 'flickity/css/flickity.css'


interface iProps {
  pageProps: any
}

export function reportWebVitals(metric: NextWebVitalsMetric) {
  if(!metric.label) return
  const { label, name, startTime, value } = metric

  if (label === 'web-vital' && name === 'LCP') {
    // The metric object ({ id, name, startTime, value, label }) is logged to the console
    console.info(`${label} ${name} | startTime: ${startTime} - value: ${value}`)
  }
}

const MyApp = ({ Component, pageProps: { session, ...pageProps }}: AppProps & iProps) => {
  const { translations, layout } = pageProps

  return (
    <>
      <ModalsProvider>
        <ThemeProvider theme={theme}>
          <Reset />
          <TranslationsProvider localization={translations}>
            <Layout layout={layout} >
              <Component {...pageProps}/>
            </Layout>
          </TranslationsProvider>
          <Script id='gtm'>
            {`
                 (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                 new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                 j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                 })(window,document,'script','dataLayer','GTM-597ZMWCS');
              `}
          </Script>
          <noscript>
            <iframe src='https://www.googletagmanager.com/ns.html?id=GTM-597ZMWCS' height='0' width='0' style={{ display: 'none', visibility: 'hidden'}}></iframe>
          </noscript>
        </ThemeProvider>
      </ModalsProvider>
    </>
  )
}

//@ts-ignore
export default MyApp
