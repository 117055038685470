import styled from 'styled-components'
import { vw, mq } from '@dy/commons/styles'
import { Figure, Logo, Link, Burger } from '@dy/commons/components'
import { Dropdown } from '@dy/commons/components'
import { useModal } from '@dy/commons/hooks'
import { MODALS } from '@dy/commons/context'
import { useRouter } from 'next/router'
import { getLang } from '@dy/commons/utils'

const NavBar = styled.nav<any>`
  align-items: center;
  display: flex;
  flex-direction: row;
  grid-column: 1 / span 6;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 100;

  /* MOBILE LANDSCAPE */
  @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
    height: 78px;
    padding: 15px 17px;

    > .logo {
      figure {
        height: 28px;
        width: 207px;
      }
    }

    > button {
      width: 60px;

      &,
      figure {
        height: 60px;
        width: 60px;
      }
    }
    ${mq.lessThan<any>('tablet')`
      background-color: ${({ $menuOpen, theme }) => $menuOpen ? theme.colors.darkgreen : 'transparent'};
    `}
  }

  height:${vw(90, 'mobile')};
  padding:${vw(15, 'mobile')} ${vw(17, 'mobile')};

  ${mq.greaterThan('nexus7')`
    height: 131px;
    padding: 21.8px 24.7px;
  `}

  ${mq.greaterThan('tablet')`
    grid-column: 9 / span 2;
    height: ${vw(78, 'desktop')};
    padding: ${vw(25, 'desktop')} ${vw(50, 'desktop')};
  `}

  ${mq.greaterThan('container')`
    grid-column: 9 / span 2;
    height: 63.32px;
    padding: 20.295px 40.59px;
  `}
`

const Wrapper = styled.div`
  display: none;

  ${mq.greaterThan('tablet')`
    align-items: center;
    display: flex;
    flex-direction: row;
  `}

  figure {
    ${mq.greaterThan('tablet')`
      display:block;
      height: ${vw(28, 'desktop')};
      margin-right: ${vw(20, 'desktop')};
      width: ${vw(28, 'desktop')};
    `}
    
    ${mq.greaterThan('container')`
      display:block;
      height: 22.72px;
      margin-right: 16.2px;
      width: 22.72px;
    `}
  }
`
const mediaInstagram = {
  type: 'image' as const,
  alt: 'instagram',
  default: {
    src:'/images/svg/i--instagram.svg',
    size: { width: 24, height: 24 }
  }
}
const mediaTiktok = {
  type: 'image' as const,
  alt: 'instagram',
  default: {
    src:'/images/svg/i--tiktok.svg',
    size: { width: 24, height: 24 }
  }
}

const arrayOptions = [
  {value: 'ES', path:'/'},
  {value: 'CA', path:'/ca'},
  {value: 'EN', path:'/en'}
]

export const Nav = () => {
  const { isActive:isActiveModalMenu, toggle: toggleModalMenu } = useModal(MODALS.MENU)
  const { locale:routerLocale, pathname } = useRouter()
  const locale = getLang(routerLocale, pathname)

  return (
    <NavBar $menuOpen={isActiveModalMenu}>
      <Logo name={'iluzione'}/>
      <Burger onClick={toggleModalMenu} menuOpen={isActiveModalMenu} />
      <Wrapper>
        <Link href='https://www.instagram.com/iluzione_restaurant/' className='instagram'>
          <Figure media={mediaInstagram}/>
        </Link>
        <Link href='https://www.tiktok.com/@iluzione_restaurant'>
          <Figure media={mediaTiktok}/>
        </Link>
        <Dropdown className='detail-dropdown' trigger={locale.toUpperCase()} options={arrayOptions} direction={'down'} />
      </Wrapper>
    </NavBar>
  )
}
