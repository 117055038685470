"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CartProvider = exports.CartContext = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const initialCartState = { id: null, cartLines: [] };
exports.CartContext = (0, react_1.createContext)(initialCartState);
const cartReducer = (state = initialCartState, action) => {
    const { type, payload } = action;
    switch (type) {
        case 'ADD':
        case 'REMOVE':
        case 'DELETE':
        case 'BULKUPDATE':
            return Object.assign(Object.assign({}, state), { cartLines: payload.cartLines });
        default:
            throw new Error(`cartReducer: No case for type: ${type}`);
    }
};
const CartProvider = ({ children }) => {
    const [state, dispatch] = (0, react_1.useReducer)(cartReducer, initialCartState);
    const addToCart = (variant) => {
        const index = state.cartLines.findIndex(item => item.id === variant.id);
        let updatedCart = [...state.cartLines];
        if (index !== -1) {
            updatedCart[index] = variant;
        }
        else {
            updatedCart.push(variant);
        }
        dispatch({ type: 'ADD',
            payload: {
                cartLines: updatedCart
            }
        });
    };
    const removeFromCart = (variant) => {
        const index = state.cartLines.findIndex(item => item.id === variant.id);
        let updatedCart = [];
        if (index !== -1 && updatedCart[index].units > 0) {
            updatedCart = [...state.cartLines];
            updatedCart[index] = variant;
        }
        else {
            updatedCart = state.cartLines.filter(item => item.id !== variant.id);
        }
        dispatch({ type: 'REMOVE',
            payload: {
                cartLines: updatedCart
            }
        });
    };
    const deleteFromCart = (variantId) => {
        const updatedCart = state.cartLines.filter(item => item.id !== variantId);
        dispatch({ type: 'DELETE',
            payload: {
                cartLines: updatedCart
            }
        });
    };
    const bulkUpdateCart = (cartLines) => {
        dispatch({ type: 'BULKUPDATE',
            payload: {
                cartLines
            }
        });
    };
    return ((0, jsx_runtime_1.jsx)(exports.CartContext.Provider, Object.assign({ value: {
            id: state.id,
            cartLines: state.cartLines,
            addToCart,
            removeFromCart,
            deleteFromCart,
            bulkUpdateCart
        } }, { children: children })));
};
exports.CartProvider = CartProvider;
