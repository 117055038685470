"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useScrollTracker = void 0;
const react_1 = require("react");
const SCROLL = {
    currentState: 'TOP',
    lastScroll: 0,
    getCurrentState(currentScroll, maxHeight) {
        if (currentScroll < maxHeight)
            this.currentState = 'TOP';
        else {
            if (this.lastScroll < currentScroll)
                this.currentState = 'SCROLLING_DOWN';
            else if (this.lastScroll > currentScroll)
                this.currentState = 'SCROLLING_UP';
        }
        this.lastScroll = currentScroll;
        return this.currentState;
    }
};
const useScrollTracker = (maxHeight = 60) => {
    const [scrollState, setScrollState] = (0, react_1.useState)('TOP');
    const trackScroll = () => {
        const newState = SCROLL.getCurrentState(window.scrollY, maxHeight);
        setScrollState((currentState) => {
            return (currentState !== newState) ? newState : currentState;
        });
    };
    const debounceTrackScroll = () => requestAnimationFrame(trackScroll);
    (0, react_1.useEffect)(() => {
        window.addEventListener('scroll', debounceTrackScroll, { passive: true });
        return () => window.removeEventListener('scroll', debounceTrackScroll);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [maxHeight]);
    return scrollState;
};
exports.useScrollTracker = useScrollTracker;
